@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700&display=swap');


.hero-container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
#text-area {
    height: 100px;
}
li {
    list-style: none;
    line-height: 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.step {
    background: rgb(80, 99, 207);
    min-width: 35px;
    height: 100%;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    color: #fff;
}
li .step p {
    font-size: 2rem;
    background: black;
}
